.Toastify__toast--info {
  background: #0381fe;
}

.Toastify__toast--success {
  background: #128fe3;
}

.Toastify__toast--warning {
  background: #dccbb5;
}

.Toastify__toast--error {
  background: #d1321d;
}